import routes from './_routes'
import { RouteHandler, ScrollToTop } from './components'
import AccountStore from './stores/Account'
import CssBaseline from '@material-ui/core/CssBaseline'
import _ from 'lodash'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'

import { createTheme } from '@material-ui/core/styles'
import { Shadows } from '@material-ui/core/styles/shadows'
import { ThemeProvider } from '@material-ui/styles'

type Contrast = 'light' | 'dark'

const primary = 'rgb(20, 10, 154)'

const primaryColor = {
  50: primary,
  100: primary,
  200: primary,
  300: primary,
  400: primary,
  500: primary,
  600: primary,
  700: primary,
  800: primary,
  900: primary,
  A100: primary,
  A200: 'white',
  A400: 'white',
  A700: 'white',
  contrastDefaultColor: 'light' as Contrast
}

const primaryColorCastrol = {
  50: '#00833b',
  100: '#00833b',
  200: '#00833b',
  300: '#00833b',
  400: '#00833b',
  500: '#00833b',
  600: '#00833b',
  700: '#00833b',
  800: '#00833b',
  900: '#00833b',
  A100: 'rgb(192, 193, 247)',
  A200: 'white',
  A400: 'white',
  A700: 'white',
  contrastDefaultColor: 'light' as Contrast
}

const secondaryColorCastrol = {
  50: '#f5564a',
  100: '#f5564a',
  200: '#f5564a',
  300: '#f5564a',
  400: '#f5564a',
  500: '#f5564a',
  600: '#f5564a',
  700: '#f5564a',
  800: '#f5564a',
  900: '#f5564a',
  A100: '#f5564a',
  A200: '#f5564a',
  A400: '#f5564a',
  A700: '#f5564a',
  contrastDefaultColor: 'dark' as Contrast
}

const secondaryColor = {
  50: '#ffd700',
  100: '#ffd700',
  200: '#ffd700',
  300: '#ffd700',
  400: '#ffd700',
  500: '#ffd700',
  600: '#ffd700',
  700: '#ffd700',
  800: '#ffd700',
  900: '#ffd700',
  A100: '#ffd700',
  A200: '#ffd700',
  A400: '#ffd700',
  A700: '#ffd700',
  contrastDefaultColor: 'dark' as Contrast
}

const primaryColorCaltex = {
  50: '#014B60',
  100: '#014B60',
  200: '#014B60',
  300: '#014B60',
  400: '#014B60',
  500: '#014B60',
  600: '#014B60',
  700: '#014B60',
  800: '#014B60',
  900: '#014B60',
  A100: 'white',
  A200: 'white',
  A400: 'white',
  A700: 'white',
  contrastDefaultColor: 'light' as Contrast
}

const secondaryColorCaltex = {
  50: '#f5564a',
  100: '#f5564a',
  200: '#f5564a',
  300: '#f5564a',
  400: '#f5564a',
  500: '#f5564a',
  600: '#f5564a',
  700: '#f5564a',
  800: '#f5564a',
  900: '#f5564a',
  A100: '#f5564a',
  A200: '#f5564a',
  A400: '#f5564a',
  A700: '#f5564a',
  contrastDefaultColor: 'dark' as Contrast
}

/* Extended Caltex palette for reference
{
  grey: {
    light: '#D0D0D0',
    medium: '#979A9C'
  },
  havoline: {
    black: '#211E16',
    darkRed: '#5B1400',
    gold: '#FDB515'
  },
  delo: {
    blue: '#2459A9',
    darkBlue: '#003764',
    lightBlue: '#62B3E4'
  }
}
*/

const App = observer(
  class App extends React.Component {
    theme: any = null

    constructor(props: any) {
      super(props)

      makeObservable(this, {
        theme: observable
      })
    }

    componentDidMount = action(() => {
      if (
        !_.includes(window.location.pathname, '/staff') &&
        !AccountStore.isWhitelabel &&
        !_.includes(window.location.pathname, '/logged-out') &&
        !_.includes(window.location.pathname, '/login-failed')
      ) {
        AccountStore.isSharedLogin = true
      }
      AccountStore.getProfile()

      this.theme = createTheme({
        overrides: {
          MuiTypography: {
            subtitle1: {
              fontWeight: 'bold'
            }
          },
          // make dialogs look better on mobile
          MuiDialog: {
            paperFullWidth: {
              margin: 0,
              width: 'calc(100% - 48px)'
            }
          },
          MuiButton: {
            root: {
              textTransform: 'none'
            }
          },
          MuiTab: {
            root: {
              textTransform: 'none'
            }
          }
        },
        typography: {
          fontFamily: [
            // ...(AccountStore.isLubeWatch ? ['ITC Franklin Gothic LT'] : []),
            'Noto Sans',
            'Helvetica',
            'Arial',
            'sans-serif'
          ].join(','),
          fontSize: 13,
          fontWeightRegular: 400,
          fontWeightBold: 600
        },
        palette: {
          type: 'light',
          primary: AccountStore.isWhitelabel
            ? AccountStore.isLabCheck
              ? primaryColorCastrol
              : AccountStore.isLubeWatch
              ? primaryColorCaltex
              : primaryColor
            : primaryColor,
          secondary: AccountStore.isWhitelabel
            ? AccountStore.isLabCheck
              ? secondaryColorCastrol
              : AccountStore.isLubeWatch
              ? secondaryColorCaltex
              : secondaryColor
            : secondaryColor
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 650,
            md: 960,
            lg: 1280,
            xl: 1920
          }
        }
      })

      // Whitelabel modifications
      if (AccountStore.isWhitelabel) {
        if (AccountStore.isLabCheck) {
          document.body.classList.add('castrol')
          document.title = 'Castrol Labcheck'
        } else if (AccountStore.isLubeWatch) {
          document.body.classList.add('chevron')
          document.title = 'Caltex LubeWatch'
        }
      } else {
        document.title = 'CMS | myKomatsu'
      }
    })

    render() {
      return (
        <Router basename={!AccountStore.isWhitelabel ? '/cms' : '/'}>
          <LastLocationProvider>
            <ScrollToTop>
              {this.theme && (
                <ThemeProvider theme={this.theme}>
                  <CssBaseline />
                  <RouteHandler
                    isAuthenticated={AccountStore.meta.isAuthenticated}
                    routes={routes}
                  />
                </ThemeProvider>
              )}
            </ScrollToTop>
          </LastLocationProvider>
        </Router>
      )
    }
  }
)

export default App
